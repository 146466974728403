<template>
  <v-system-bar
    window
    dark
    app
    class="text-center"
    v-if="
      environment &&
        environment.VUE_APP_ENV_NAME &&
        environment.NODE_ENV &&
        environment.VUE_APP_ENV_NAME.toLowerCase() != 'production' &&
        showSystemBar
    "
  >
    <v-row justify="center">
      <span>{{ environment ? environment.VUE_APP_ENV_NAME : "" }} Environment</span>
      <slot name="additional-messages"></slot>
    </v-row>
    <v-icon @click="showSystemBar = false">mdi-close</v-icon>
  </v-system-bar>
</template>

<script>
export default {
  name: "EnvironmentSystemBar",
  props: {
    environment: Object
  },
  data: () => ({
    showSystemBar: true
  })
};
</script>
