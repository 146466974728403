<template>
  <EnvironmentSystemBar :environment="environment">
    <template v-slot:additional-messages
      >s :
      <a :href="'//' + environment.VUE_APP_PROGRAM_EUR_US_DOMAIN">Pro End Users</a>
      <a :href="'//' + environment.VUE_APP_PROGRAM_EUR_US_DOMAIN + '/#/eur/service-and-support'"
        >Pro Service &amp; Support</a
      >
      <a :href="'//' + environment.VUE_APP_PROGRAM_CPR_US_DOMAIN">Pro Channel Partners</a>
      <a :href="'//' + environment.VUE_APP_PROGRAM_CEUR_US_LANDING_DOMAIN">Consumer Landing</a>
      <a :href="'//' + environment.VUE_APP_PROGRAM_CEUR_US_DOMAIN">Consumer End Users</a>
      <a :href="'//' + environment.VUE_APP_PROGRAM_CCPR_US_DOMAIN">Consumer RSA / SPIFF</a>
    </template>
  </EnvironmentSystemBar>
</template>

<script>
import EnvironmentSystemBar from "../gapp-components/components/display/EnvironmentSystemBar.vue";

export default {
  name: "SonyEnvironmentSystemBar",
  components: {
    EnvironmentSystemBar
  },
  computed: {
    environment() {
      return process.env;
    }
  }
};
</script>
